import { z } from "zod";
import {
  booleanComparisonExpSchema,
  nonEmptyStringSchema,
  orderBySchema,
  roundingNumberSchema,
  uuidComparisonExpSchema,
} from ".";

export const plansBoolExpSchema = z.object({
  id: uuidComparisonExpSchema.nullish(),
  isHidden: booleanComparisonExpSchema.nullish(),
  isAnnual: booleanComparisonExpSchema.nullish(),
});

export type PlansBoolExpSchema = z.infer<typeof plansBoolExpSchema>;

export const plansOrderBySchema = z.object({
  sortPriority: orderBySchema.nullish(),
});

export type PlansOrderBySchema = z.infer<typeof plansOrderBySchema>;

const plansFlagsSchema = z.object({
  enableFixedAssetsModule: z.boolean(),
  enableReportingDimensions: z.boolean(),
  enableInventoryTracking: z.boolean(),
  enableManualJournals: z.boolean(),
});

export type PlansFlagsSchema = z.infer<typeof plansFlagsSchema>;

export const plansDetailsSchema = z.object({
  title: z.object({
    en: nonEmptyStringSchema,
    ar: nonEmptyStringSchema,
  }),
  subtitle: z.object({
    en: nonEmptyStringSchema,
    ar: nonEmptyStringSchema,
  }),
  features: z
    .object({
      en: z.array(nonEmptyStringSchema),
      ar: z.array(nonEmptyStringSchema),
    })
    .superRefine((data, ctx) => {
      // Check if all the features arrays are of the same length
      const lengthSet = new Set<number>();
      for (const key in data) {
        lengthSet.add(data[key as keyof typeof data].length);
      }

      if (lengthSet.size !== 1) {
        ctx.addIssue({
          code: "custom",
          path: ["error"],
          message: "Features must be of the same length in all languages",
          params: {
            i18n: "custom.features_length",
          },
        });
      }
    }),
});

export type PlansDetailsSchema = z.infer<typeof plansDetailsSchema>;

export const plansInsertInputSchema = z
  .object({
    name: z.string().regex(/^[a-z0-9-]{3,15}$/, {
      message:
        "Name must be between 3 and 15 characters long and can only contain lowercase letters, numbers and dashes",
    }),
    price: roundingNumberSchema.pipe(z.number().int().gte(0)),
    isAnnual: z.boolean(),
    isHidden: z.boolean(),
    sortPriority: z.number().int().gt(0).lt(10000),
    userPrice: roundingNumberSchema.pipe(z.number().int().gte(0)),
    posUnitPrice: roundingNumberSchema.pipe(z.number().int().gte(0)),
    defaultUsersSeats: z.number().int().gte(0).lt(10000),
    defaultPosTerminals: z.number().int().gte(0).lt(10000),
    details: plansDetailsSchema,
  })
  .merge(plansFlagsSchema);

export type PlansInsertInputSchema = z.infer<typeof plansInsertInputSchema>;

export const plansSetInputSchema = plansInsertInputSchema.partial();

export type PlansSetInputSchema = z.infer<typeof plansSetInputSchema>;
