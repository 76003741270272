import { z } from "zod";
import {
  bilingualTextSchema,
  booleanComparisonExpSchema,
  dateComparisonExpSchema,
  dateStringSchema,
  emptyStringSchema,
  numericComparisonExpSchema,
  orderBySchema,
  uuidComparisonExpSchema,
} from ".";
import { plansBoolExpSchema } from "./Plans";
import { orderProductsAddonType } from "@mono/constants/lib/billing";

const ordersBoolExpProperties = z.object({
  orgId: uuidComparisonExpSchema.nullish(),
  dueDate: dateComparisonExpSchema.nullish(),
  cancellationDate: dateComparisonExpSchema.nullish(),
  invoiceIssueDate: dateComparisonExpSchema.nullish(),
  price: numericComparisonExpSchema.nullish(),
  activePlanId: uuidComparisonExpSchema.nullish(),
  plan: plansBoolExpSchema.nullish(),
});

export type OrdersBoolExpSchema = z.infer<typeof ordersBoolExpProperties> & {
  _and?: OrdersBoolExpSchema[] | null;
};

export const ordersBoolExpSchema: z.ZodType<OrdersBoolExpSchema> =
  ordersBoolExpProperties.extend({
    _and: z.lazy(() => ordersBoolExpSchema.array().nullish()),
  });

export const ordersOrderBySchema = z.object({
  createdAt: orderBySchema.nullish(),
});

export type OrdersOrderBySchema = z.infer<typeof ordersOrderBySchema>;

export const ordersSettingsIncInputSchema = z.object({
  invoicesCounter: z.number(),
});

export type OrdersSettingsIncInputSchema = z.infer<
  typeof ordersSettingsIncInputSchema
>;

export const ordersSetInputSchema = z
  .object({
    invoiceReference: z.string(),
    invoiceIssueDate: dateStringSchema,
  })
  .partial();

export type OrdersSetInputSchema = z.infer<typeof ordersSetInputSchema>;

const orderPaymentsBoolExpProperties = z.object({
  paymentDate: dateComparisonExpSchema.nullish(),
  isSuccessful: booleanComparisonExpSchema.nullish(),
  order: ordersBoolExpSchema.nullish(),
});

export type OrderPaymentsBoolExpSchema = z.infer<
  typeof orderPaymentsBoolExpProperties
> & {
  _and?: OrderPaymentsBoolExpSchema[] | null;
};

export const orderPaymentsBoolExpSchema: z.ZodType<OrderPaymentsBoolExpSchema> =
  orderPaymentsBoolExpProperties.extend({
    _and: z.lazy(() => orderPaymentsBoolExpSchema.array().nullish()),
  });

export const addManualPaymentRecordArgsSchema = z.object({
  orderId: z.string().uuid(),
  paymentMethodId: z.string().uuid(),
  isSuccessful: z.boolean(),
  amount: z.number().int().gt(0),
  comment: bilingualTextSchema.min(2).max(50).or(emptyStringSchema),
  paymentDate: dateStringSchema,
});

export type AddManualPaymentRecordArgsSchema = z.infer<
  typeof addManualPaymentRecordArgsSchema
>;

export const orderProductsSchema = z.object({
  addonType: z.enum(orderProductsAddonType),
  quantity: z.number().int().gt(0),
});

export type OrderProductsSchema = z.infer<typeof orderProductsSchema>;
